<template>
  <b-row no-gutters>
    <b-col cols="12" class="modifier-header py-2 px-3 d-flex flex-wrap align-center"
      :class="{ 'bottom-border': !collapse }">
      <h3 class="mb-0 w-100 text-left">
        {{ value.name }}
        <b-btn class="float-right btn toggle-btn" @click="toggle">
          <b-icon-caret-right-fill v-if="!collapse" size="md" />
          <b-icon-caret-down-fill v-else size="md" />
        </b-btn>
      </h3>
      <span v-if="collapse || !valid" :class="{ 'text-error': !valid }">
        <template v-if="value.min_items === value.max_items">Please select {{ value.min_items }} item<template
            v-if="value.min_items > 1">s</template></template>
        <template v-else-if="value.min_items >= 0 && value.max_items !== null">
          Please select
          <template v-if="value.min_items > 0">at least {{ value.min_items }} or</template>
          up to {{ value.max_items }} item<template v-if="value.max_items > 1">s</template>
        </template>
        <template v-else>Select as many as you like</template>
      </span>
      <span v-else-if="selectedModifiers > 0">
        {{ selectedModifiers }} selected
        <span v-if="parseFloat(modifierSubtotal) > 0">:
          <currency primary-font-size="1rem" secondary-font-size="0.9rem" prepend="$" :currency="modifierSubtotal" />
        </span>
      </span>
      <span v-else> None selected </span>
    </b-col>
    <b-col cols="12">
      <b-collapse v-model="collapse">
        <div v-for="(item, i) in value.items" :key="i" class="col-12">
          <modifier-item v-model="value.items[i]" :class="{ 'bg-grey': i % 2 === 0 }"
            :disabled="maxItemsReached || shouldBeDisabledBaseOnBinary(item)" />
        </div>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import ModifierItem from '@/shop/product-partials/modifiers/ModifierItem.vue';
import Currency from '@/Currency.vue';

export default {
  name: 'Modifier',
  components: { Currency, ModifierItem },
  props: {
    value: Object,
    valid: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    isModsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    selectedModifiersIndexes() {
      return this.value.items
        .filter((item) => item.item_quantity > 0)
        .map((item) => item.uuid);
    },
    selectedModifiers() {
      return this.value.items.reduce((a, item) => a + item.item_quantity, 0);
    },
    modifierSubtotal() {
      return this.value.items.reduce((a, item) => {
        if (item.is_floor_price)
          return a += Math.max(item.item_quantity, item.default_quantity) * item.price_adjustment;

        return a += item.item_quantity * item.price_adjustment;
      }, 0).toFixed(2);
    },
    maxItemsReached() {
      return (
        this.selectedModifiers >= this.value.max_items &&
        this.value.max_items !== null
      ) || this.isModsDisabled
    },
  },
  methods: {
    shouldBeDisabledBaseOnBinary(item) {
      if (this.value.is_binary) {
        const firstSelectedIndex = this.selectedModifiersIndexes[0];
        if (!firstSelectedIndex || firstSelectedIndex === item.uuid) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    toggle(event) {
      this.collapse = !this.collapse;
      event.target.closest('.toggle-btn').blur();
    }
  },
  created() {
    this.collapse = this.expanded;
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/variables.module.scss';

@include buttons;

.w-100 {
  width: 100%;
}

.modifier-header {
  border-left: 0;
  border-right: 0;
}

.text-error {
  color: red;
}

.bg-grey {
  background-color: #f4f4f4;
}

.bottom-border {
  border-bottom: 1px solid grey;
}

h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
}
</style>
