<template>
  <b-row class="mt-4 product-tile" no-gutters align-v="center" align-h="center">
    <b-col cols="4" class="m-auto text-center pb-4">
      <b-img
        v-if="value.image"
        class="product-image shadow"
        :alt="value.title"
        :src="value.image"
        :title="value.title"
        fluid
        lazy
        width="160"
        height="160"
        @click="viewing = value"
        @error="value.image = null"
      />
      <div v-else class="image-placeholder product-placeholder">
        <div>{{ imagePlaceholder(value.title) }}</div>
      </div>
    </b-col>

    <b-col cols="12" class="product-tile__title">{{ value.title }}</b-col>

    <b-col cols="12">
      <b-form-text
        v-if="region.governance.EnableFeatureInventory"
        id="inputLiveHelp"
        class="product-quantity-feedback"
      >
        {{ stock }} in stock
      </b-form-text>
      <b-form-text
        v-else-if="isSmartWeight && !region.governance.EnableFeatureSmartWeight"
        id="inputLiveHelp"
        class="product-quantity-feedback"
      >
        SmartWeight&trade; products aren't available in the
        {{ region.name }} region yet. Check back soon.
      </b-form-text>

      <div v-if="region.governance.EnableFeatureModifiers" class="my-3">
        <product-modifiers
          v-model="value.modifiers"
          :validated.sync="modifiersValid"
        />
      </div>

      <b-row v-if="isRegular" align-v="center" align-h="center" no-gutters>
        <b-col cols="12" class="pb-2">
          <b-button-group class="cart-products-btn w-100">
            <b-button class="btn col-4" @click="removeFromCart(value)"
              >Remove</b-button
            >
            <b-button
              v-if="!value.max_weekly_frequency && !value.subscription"
              class="btn-outline"
              @click="subtractProductQty(value)"
            >
              <b-icon-dash></b-icon-dash>
            </b-button>
            <b-button
              v-if="!value.max_weekly_frequency && !value.subscription"
              class="btn-outline"
              @click="addProductQty(value)"
            >
              <b-icon-plus></b-icon-plus>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <template v-else-if="region.governance.EnableFeatureSmartWeight">
        <smart-weight-controls
          v-if="isSmartWeight"
          v-model="value"
          :removable="true"
          :trigger-update="true"
        />
        <fixed-weight-controls
          v-if="isFixedWeight"
          v-model="value"
          :removable="true"
          :trigger-update="true"
        />
      </template>

      <b-row
        align-v="center"
        align-h="center"
        no-gutters
        class="justify-content-between pt-1"
      >
        <b-col cols="auto">
          <span v-if="isRegular"
            >{{ value.quantityOrdered }} x
            <Currency
              primary-font-size="1em"
              secondary-font-size=".8em"
              prepend=""
              :currency="unitPrice"
            />
          </span>
        </b-col>
        <b-col cols="auto" class="font-weight-bold">
          <Currency
            primary-font-size="1rem"
            secondary-font-size=".8em"
            prepend="$"
            :currency="completePrice"
          />
        </b-col>
      </b-row>
    </b-col>

    <product-info v-if="viewing" v-model="viewing" :view-reviews="reviewing" />
    <product-reviews v-if="reviewing" v-model="reviewing" />
  </b-row>
</template>

<script>
import Currency from '@/Currency.vue';
import FixedWeightControls from '@/shop/product-partials/controls/FixedWeightControls.vue';
import SmartWeightControls from '@/shop/product-partials/controls/SmartWeightControls.vue';
import ProductInfo from '@/shop/product-partials/ProductInfo.vue';
import ProductModifiers from '@/shop/product-partials/ProductModifiers.vue';
import ProductReviews from '@/shop/product-partials/ProductReviews.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  addProductQty,
  addToCart,
  getAbrWeightLabel,
  handleSmartWeightConversion,
  imagePlaceholder,
  removeFromCart,
  subtractProductQty,
} from '^/utilities';

export default {
  name: 'CartProduct',
  components: {
    SmartWeightControls,
    FixedWeightControls,
    ProductInfo,
    ProductReviews,
    ProductModifiers,
    Currency,
  },
  props: ['value'],
  data: () => ({
    viewing: null,
    reviewing: null,
    modifiersValid: null,
  }),
  created() {
    this.$watch(
      (vm) => [
        vm.value.quantityOrdered,
        vm.value.orderedWeight,
        vm.value.modifiers,
        vm.cartInteractions,
      ],
      function () {
        this.updateQuote();
      },
      { deep: true, immediate: true }
    );
  },
  methods: {
    ...mapActions(['updateQuote']),
    getAbrWeightLabel,
    handleSmartWeightConversion,
    addToCart,
    removeFromCart,
    subtractProductQty,
    addProductQty,
    imagePlaceholder,
  },
  watch: {
    modifiersValid(val) {
      this.$store.commit(
        'toggleModifierValidation',
        val.findIndex((x) => !x) === -1
      );
    },
  },
  computed: {
    ...mapGetters(['cartInteractions', 'region']),
    isRegular() {
      return (
        !this.value.smart_weight_enabled &&
        !this.outOfStock &&
        !this.isFixedWeight
      );
    },
    isSmartWeight() {
      return (
        this.value.smart_weight_enabled &&
        !this.outOfStock &&
        !this.isFixedWeight
      );
    },
    isFixedWeight() {
      return (
        this.value.fixed_weight_enabled &&
        !this.outOfStock &&
        this.value.fixed_weight_prices.length > 0
      );
    },
    outOfStock() {
      return this.value.smart_weight_enabled
        ? this.value.weight <= 0
        : this.value.quantity <= 0;
    },
    completePrice() {
      let price = 0.0;
      if (this.value.fixed_weight_enabled) {
        // Find matching option
        let opt = this.value.fixed_weight_prices.find(
          (o) =>
            o.amount === this.value.quantityOrdered &&
            o.weight === this.value.orderedWeight
        );
        if (opt) price = opt.price;
      } else if (this.value.smart_weight_enabled) {
        price = (
          handleSmartWeightConversion(
            this.value.quantityOrdered,
            this.value.orderedWeight,
            this.value.sold_by_weight
          ) * this.value.price
        ).toFixed(2);
      } else {
        price = (this.value.quantityOrdered * this.value.price).toFixed(2);
      }

      if (this.region.governance.EnableFeatureModifiers) {
        price = (
          parseFloat(price) +
          this.value.modifiers
            .map((x) =>
              x.items.reduce(
                (a, item) => {
                  if(item.is_floor_price)
                    return a += Math.max(item.item_quantity, item.default_quantity) * item.price_adjustment;

                  return a += item.item_quantity * item.price_adjustment;
                }
              , 0)
            )
            .reduce((a, b) => a + b, 0) *
            this.value.quantityOrdered
        ).toFixed(2);
      }

      return price;
    },
    unitPrice() {
      return (this.completePrice / this.value.quantityOrdered).toFixed(2);
    },
    stock() {
      let stock = this.value.smart_weight_enabled
        ? parseFloat(this.value.weight).toFixed(2)
        : this.value.quantity;
      // For now if the stock amount is too large, let's display less until we have security inplace to control the displayed amount
      return stock > 100 ? 100 : stock;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.cart-products-btn {
  @include buttons;

  .btn {
    box-shadow: none !important;
    font-size: 110%;
  }

  & {
    justify-content: center;
  }
}

@media #{$smAndAbove} {
  .product-right-column {
    margin-left: 15px;
  }
}

@media #{$xs} {
  .product-right-column {
    margin-left: 0;
    margin-top: 15px;
  }
}

.w-100 {
  width: 100%;
}

.cart-products-btn {
  button {
    border-radius: 0 !important;
  }
}

.product-placeholder {
  width: 160px;
  min-height: 160px;
}

.product-tile {
  border-radius: 5px;

  &__title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .product-title {
    color: black;
  }

  .product-qty-input {
    font-size: 0.85em;
    width: 70px;
    height: 38px !important;
  }

  .product-img-wrapper {
    position: relative;

    img {
      max-width: 120px;
    }

    .badge {
      padding-top: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-size: 0.85em;
      letter-spacing: 0.085em;
      font-family: $staatliches-font;
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 170px;
      text-align: center;
      background-color: white;
      border: 1px solid #dee2e6;
      border-top: none;
      color: var(--primary);
    }
  }
}

.tag-space {
  margin-right: 2px;
}

div > .tag-space:last-child {
  margin-right: 0;
}

.sub-info {
  border-radius: 4px;
  font-size: 1em;
  color: #555;
  font-weight: bold;
}

.product-image {
  border: 1px solid var(--primary);
  border-radius: 5px;
}
</style>
