<template>
  <div>
    <p v-if="label" class="title">{{ label }}</p>
    <Multiselect
      v-bind="$attrs"
      v-model="selectedValue"
      class="multiselect"
      v-on="$listeners"
    >
    <template v-slot:nooptions>
      <slot/>
    </template>
  </Multiselect>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import '@vueform/multiselect/themes/default.css';

export default {
  name: 'MyMultiselect',
  components: {
    Multiselect,
  },
  props: {
    label: { type: String, default: '' },
    value: { type: null, default: '' },
  },
  data() {
    return {
      selectedValue: '',
    };
  },
  created() {
    this.selectedValue = this.value;
    this.$watch('selectedValue', (value) => {
      this.$emit('input', value);
    });
    this.$watch('value', (value) => {
      this.selectedValue = value;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.title {
  font-family: $main-font;
  font-weight: 700;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 0;
  color: $dark-gray;
}

.multiselect {
  font-family: $main-font;
  font-weight: bold;

    max-height: 10px !important;

  --ms-py: 0.5rem;
  --ms-px: 0.875rem;

  --ms-font-size: 12px;
  --ms-font-weigh: bold;
  --ms-font-family: ;

  --ms-line-height: 1.375;
  --ms-border-color: var(--dark-gray);
  --ms-input-border-width: 0;
  --ms-border-color-active: var(--primary);
  --ms-border-width-active: 1px;
  --ms-radius: 4px;

  --ms-ring-width: 0px;
  --ms-placeholder-color: var(--dark-gray);
  --ms-max-height: 10rem;

  --ms-caret-color: var(--dark-gray);
  --ms-clear-color: var(--dark-gray);
  --ms-clear-color-hover: var(--dark-gray);

  --ms-tag-remove-radius: 4px;
  --ms-tag-remove-py: 0.25rem;
  --ms-tag-remove-px: 0.25rem;
  --ms-tag-remove-my: 0rem;
  --ms-tag-remove-mx: 0.125rem;

  --ms-dropdown-bg: white;
  --ms-dropdown-border-color: var(--primary);
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 4px;

  --ms-option-font-size: 12px;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: var(--light-gray);
  // --ms-option-color-pointed: var(--primary);
  --ms-option-bg-selected: var(--primary);
  --ms-option-color-selected: white;
  --ms-option-bg-selected-pointed: var(--primary);
  --ms-option-color-selected-pointed: white;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;
}
</style>
