import {
  smartWeightOptions,
  smartWeightOptionsEnum,
} from '~/enums/smartWeightOptions';

export const createPhoneMask = function (string) {
  return string.replace(/(\d{3})(\d{3})(\d{3})/, '($1)-$2-$3');
};

export const destroyPhoneMask = function (string) {
  return string.replace(/\D/g, '').substring(0, 10);
};

export const formatMoney = function (value) {
  let val = (value / 1).toFixed(2);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const paramArray = function (name, items) {
  const params = {};
  for (let i = 0; i < items.length; i++) {
    params[`${name}[${i}]`] = `${items[i]}`;
  }
  return params;
};

export const handleSmartWeightConversion = function (value, from, to) {
  if (from === to) return value;
  else if (
    from === smartWeightOptionsEnum.MILLIGRAMS &&
    to === smartWeightOptionsEnum.GRAMS
  )
    value = value / 1000;
  else if (
    from === smartWeightOptionsEnum.GRAMS &&
    to === smartWeightOptionsEnum.MILLIGRAMS
  )
    value = value * 1000;
  else if (
    from === smartWeightOptionsEnum.MILLIGRAMS &&
    to === smartWeightOptionsEnum.OZ
  )
    value = value / 28349.5231;
  else if (
    from === smartWeightOptionsEnum.OZ &&
    to === smartWeightOptionsEnum.MILLIGRAMS
  )
    value = value * 28349.5231;
  else if (
    from === smartWeightOptionsEnum.GRAMS &&
    to === smartWeightOptionsEnum.OZ
  )
    value = value / 28.3495231;
  else if (
    from === smartWeightOptionsEnum.OZ &&
    to === smartWeightOptionsEnum.GRAMS
  )
    value = value * 28.3495231;

  return Math.floor(value) === value ? value : Math.floor(value * 100) / 100;
};

export const calculatePricePerByWeight = function (product, orderedWeight) {
  let val = handleSmartWeightConversion(
    product.price,
    orderedWeight,
    product.sold_by_weight
  );
  return formatMoney(val);
};

export const addToCart = function (product, callback = null) {
  this.$store.commit('addProductToCart', product);
  if (callback) callback();
};

export const removeFromCart = function (product) {
  this.$store.commit('removeProductFromCart', product);
};

export const removeCart = function () {
  this.$store.commit('removeProductsFromCart');
};

export const subtractProductQty = function (product) {
  if (product.quantityOrdered > 1) {
    product.quantityOrdered--;
  }
};

export const addProductQty = function (product) {
  if (product.quantityOrdered) {
    product.quantityOrdered++;
  }
};

export const getAbrWeightLabel = function (weight) {
  return weight > 0 && weight < smartWeightOptions.length
    ? smartWeightOptions[weight].text
    : '';
};

export const roundToTwoDecimals = function (num) {
  return parseFloat(`${num}`).toFixed(2);
};

export const bankersRound = function (n, d = 2) {
  var x = n * Math.pow(10, d);
  var r = Math.round(x);
  var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, d);
};

// Order Conditions
export const minimumSubtotalCheck = function (vendor, quote) {
  if (vendor && quote && vendor.minimum_order_subtotal) {
    const sub =
      typeof quote.subtotal === 'string'
        ? quote.subtotal.replace(',', '')
        : quote.subtotal;

    return parseFloat(vendor.minimum_order_subtotal) <= parseFloat(sub);
  } else {
    return true;
  }
};

export const maximumOrderWeightCheck = function (region, cartProducts) {
  if (region && region.max_weight_per_order_grams) {
    return (
      getCartTotalWeightGrams(cartProducts) <= region.max_weight_per_order_grams
    );
  } else {
    return true;
  }
};

export const getCartTotalWeightGrams = function (cartProducts) {
  return cartProducts
    .filter((p) => p.smart_weight_enabled)
    .map((p) =>
      handleSmartWeightConversion(
        p.quantityOrdered,
        p.orderedWeight,
        smartWeightOptionsEnum.GRAMS
      )
    )
    .reduce((ttl, cur) => ttl + cur, 0);
};

export const getIngredientMacros = function (
  ingredient,
  amount,
  portion = null
) {
  let grams =
    (portion ? portion.gram_weight : ingredient.serving_size) * amount;

  return {
    calories: Math.round(
      (grams / ingredient.serving_size) * ingredient.calories
    ),
    proteins: Math.round(
      (grams / ingredient.serving_size) * ingredient.proteins
    ),
    carbs: Math.round((grams / ingredient.serving_size) * ingredient.carbs),
    fats: Math.round((grams / ingredient.serving_size) * ingredient.fats),
    sugars: Math.round((grams / ingredient.serving_size) * ingredient.sugars),
    fibers: Math.round((grams / ingredient.serving_size) * ingredient.fibers),
  };
};

export const getIngredientsMacros = function (ingredients) {
  let macros = {
    calories: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    sugars: 0,
    fibers: 0,
  };

  ingredients.forEach((i) => {
    if (!i.portion) return;

    let ingredientMacros = getIngredientMacros(
      i.ingredient,
      i.amount,
      i.portion
    );

    macros.calories = macros.calories + ingredientMacros.calories;
    macros.proteins = macros.proteins + ingredientMacros.proteins;
    macros.carbs = macros.carbs + ingredientMacros.carbs;
    macros.fats = macros.fats + ingredientMacros.fats;
    macros.sugars = macros.sugars + ingredientMacros.sugars;
    macros.fibers = macros.fibers + ingredientMacros.fibers;
  });

  return macros;
};

export const getModifierItemMacros = function (item, item_quantity = null) {
  let macros = {
    calories: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    sugars: 0,
    fibers: 0,
  };

  if (item.custom_macros && item.item_quantity) {
    macros.calories = item.custom_macros.calories * item.item_quantity;
    macros.proteins = item.custom_macros.proteins * item.item_quantity;
    macros.carbs = item.custom_macros.carbs * item.item_quantity;
    macros.fats = item.custom_macros.fats * item.item_quantity;
    macros.sugars = item.custom_macros.sugars * item.item_quantity;
    macros.fibers = item.custom_macros.fibers * item.item_quantity;
  } else {
    let ingredients = [];

    // todo: make recursive
    if(item.recipes && item.recipes.length > 0){
      item.recipes.forEach((r) => r.ingredients.forEach((ri) => ingredients.push(ri)));
    }
    
    if(item.ingredients && item.ingredients.length > 0) {
      ingredients.concat(item.ingredients).forEach((mi) => {
        if (!mi.portion || !mi.portion) return;

        let ingredientMacros = getIngredientMacros(
          mi.ingredient,
          mi.amount,
          mi.portion
        );
        let quantity = item.item_quantity ?? item_quantity ?? 0;

        macros.calories = macros.calories + ingredientMacros.calories * quantity;
        macros.proteins = macros.proteins + ingredientMacros.proteins * quantity;
        macros.carbs = macros.carbs + ingredientMacros.carbs * quantity;
        macros.fats = macros.fats + ingredientMacros.fats * quantity;
        macros.sugars = macros.sugars + ingredientMacros.sugars * quantity;
        macros.fibers = macros.fibers + ingredientMacros.fibers * quantity;
      });      
    }
  }
  return macros;
};

export const getProductMacros = function (
  product,
  consider_mods = true,
  default_mod_quantity = 0
) {
  let macros = [];
  if (product.custom_macros) {
    macros.push(product.custom_macros);
  } else if(product.ingredients) {
    product.ingredients.forEach((pi) => {
      macros.push(getIngredientMacros(pi.ingredient, pi.amount, pi.portion));
    });
  }

  // todo: make recursive
  const recipeMacros = (recipes) => {
      recipes.forEach((r) =>{
        if (r.all_related_recipes) {
          recipeMacros(r.all_related_recipes);
        } else {
          return r.ingredients.forEach((ri) => {
            macros.push(getIngredientMacros(ri.ingredient, ri.amount, ri.portion));
          })}
        }
      );
  }
  
  if (product.recipes) {
    recipeMacros(product.recipes)
  }
  

  if (consider_mods && product.modifiers)
    product.modifiers.forEach((x) => {
      x.items.forEach((item) => {
        const itemMacros = getModifierItemMacros(item, item.default_quantity ?? default_mod_quantity);
        macros.push(itemMacros);
      });
    });

  return macros.reduce(
    function (accumulator, item) {
      accumulator.calories = accumulator.calories + item.calories;
      accumulator.proteins = accumulator.proteins + item.proteins;
      accumulator.carbs = accumulator.carbs + item.carbs;
      accumulator.fats = accumulator.fats + item.fats;
      accumulator.sugars = accumulator.sugars + item.sugars;
      accumulator.fibers = accumulator.fibers + item.fibers;
      return accumulator;
    },
    {
      calories: 0,
      proteins: 0,
      carbs: 0,
      fats: 0,
      sugars: 0,
      fibers: 0,
    }
  );
};

export const imagePlaceholder = function (str) {
  if (!str) return '';
  const words = str.trim().split(' ');
  return words.reduce((response, word) => (response += word.slice(0, 1)), ' ');
};
