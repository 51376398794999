
<div :class="isImpersonated ? 'impersonate': ''">
  <h4 v-if="isImpersonated && user" class="impersonate__text">IMPERSONATING {{ user.name }}</h4>
  <b-navbar toggleable sticky type="dark" class="dab-navbar" :class="user && auth.isAuthenticated() ? 'pr-1' : 'pr-3'">
    <!-- Region -->
    <div class="position-absolute w-100 region-button-wrapper">
      <b-button class="mx-1 region-button border border-white" title="Region" size="sm" :class="{ 'border-bottom': regionId == null }" @click="changeRegion">
        <span v-if="region" class="hidden-sm-down">{{ region.name }}</span>
        <span class="mdi mdi-map-marker-radius-outline"></span>
      </b-button>
    </div>

    <b-navbar-brand v-if="vendor" to="/" class="nav-brand">
      <b-img v-if="width >= 768" :src="vendor.settings.desktop_logo_url" />
      <b-img v-else-if="vendor.settings.mobile_logo_url" :src="vendor.settings.mobile_logo_url" />
    </b-navbar-brand>

    <b-navbar-nav class="ml-auto">
      <b-nav-form>
        <!-- Cart -->
        <b-button class="pl-2 pr-1 bg-transparent border-0" title="Cart" pill :class="{ 'border-bottom': $router.currentRoute.path === '/cart' }" @click="$router.push('/cart').catch(e => {})">
          <span class="mdi mdi-cart cart-icon"></span>
          <transition name="fade">
            <span v-if="cartProducts.length > 0" class="cart-badge badge badge-danger">{{ cartProducts.length }}</span>
          </transition>
        </b-button>
        <b-button v-b-toggle.sidebar class="ml-1 bg-transparent border-0">
          <b-icon icon="list" color="white"></b-icon>
        </b-button>
      </b-nav-form>
    </b-navbar-nav>
  </b-navbar>
  <b-container ref="container" class="app-container noselect elevation-10">
    <div v-if="showScrollIndicator && [routeMap.cart.name, routeMap.checkout.name].includes($route.name)" class="scroll-indicator w-100">
      <b-icon icon="arrow-up-short" animation="throb" class="scroll-icon" font-scale="2" @click="window.scrollTo(0, 0)"></b-icon>
    </div>

    <b-sidebar
      v-if="region"
      id="sidebar"
      v-model="sidebar"
      right
      width="100vw"
      bg-variant="dark"
      text-variant="light"
      shadow="lg"
      :title="user && auth.isAuthenticated() ? `Hi, ${user.name}` : region ? region.name : `Menu`">
      <div class="sidebar-container">
        <nav>
          <b-nav vertical class="sidebar-nav">
            <b-nav-item to="/" :class="{ active: $router.currentRoute.path === '/' }">
              <span class="mdi mdi-storefront mr-3 primary"></span>
              <span>Shop</span>
            </b-nav-item>
            <b-nav-item
              v-if="user && auth.isAuthenticated()"
              :class="{
                active: $router.currentRoute.path === routeMap.orderHistory.path
              }"
              @click="$router.push(routeMap.orderHistory.path).catch(e => {})">
              <span class="mdi mdi-history mr-3 primary"></span>
              <span>My Order History</span>
            </b-nav-item>
            <b-nav-item
              v-if="vendor && vendor.settings && vendor.settings.enable_goal_center"
              :class="{
                active: $router.currentRoute.path === routeMap.macronutrients.path
              }"
              @click="
                () => {
                  if (user && auth.isAuthenticated()) {
                    $router.push(routeMap.macronutrients.path).catch(e => {})
                  } else {
                    $router.push(routeMap.validate.path)
                  }
                }
              ">
              <span class="mr-3 pt-1 float-left">
                <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                  <macros-icon></macros-icon>
                </icon-base>
              </span>
              <span>Goal Center</span>
            </b-nav-item>

            <b-nav-item
              v-if="user && auth.isAuthenticated() && vendor && vendor.settings.enable_gift_cards"
              :class="{
                active: $router.currentRoute.path === routeMap.giftCards.path
              }"
              @click="goToGiftCards()">
              <span class="mr-3 pt-1 float-left">
                <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                  <GiftCardIcon />
                </icon-base>
              </span>
              <span>Gift cards</span>
            </b-nav-item>

            <b-nav-item
              v-if="user && auth.isAuthenticated()"
              :class="{
                active: $router.currentRoute.path === routeMap.subscriptions.path
              }"
              @click="$router.push(routeMap.subscriptions.path).catch(e => {})">
              <span class="mdi mdi-package-variant-closed mr-3 primary"></span>
              <span>My Subscriptions</span>
            </b-nav-item>
            <b-nav-item to="/map" :class="{ active: $router.currentRoute.path === '/map' }" :disabled="!regionId">
              <span class="mdi mdi-map-marker-radius-outline mr-3 primary"></span>
              <span v-if="region">{{ region.name }}</span>
              <span v-else>Delivery Hours & Area</span>
            </b-nav-item>
            <b-nav-item
              v-if="vendor && user && auth.isAuthenticated()"
              :class="{
                active: $router.currentRoute.path === routeMap.userSettings.path
              }"
              @click="
                () => {
                  if (user && auth.isAuthenticated()) {
                    $router.push(routeMap.userSettings.path).catch(e => {})
                  } else {
                    $router.push(routeMap.validate.path)
                  }
                }
              ">
              <span class="mr-3 pt-1 float-left">
                <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                  <gear-icon />
                </icon-base>
              </span>
              <span>Settings</span>
            </b-nav-item>
            <b-nav-item v-if="user && auth.isAuthenticated()" @click="onShowLogoutModal">
              <span class="mdi mdi-logout mr-3 primary"></span>
              <span>Logout</span>
            </b-nav-item>
            <template v-else>
              <b-nav-item to="/register">
                <span class="mdi mdi-form-select mr-3 primary"></span>
                <span>Register</span>
              </b-nav-item>
              <b-nav-item to="/login">
                <span class="mdi mdi-login mr-3 primary"></span>
                <span>Login</span>
              </b-nav-item>
            </template>
            <b-modal v-model="showLogoutModal" hideFooter centered title="Are you sure you want to log out?">
              <div v-if="cartProducts.length > 0" class="logout-modal-alert">You have {{ cartProducts.length }} products in your cart. Are you sure?</div>
              <b-button variant="primary" class="logout-modal-button  btn-secondary" @click="onCloseLogoutModal">Cancel</b-button>
              <b-button variant="outline-danger" class="logout-modal-button" @click="logout">Logout</b-button>
            </b-modal>

            <template v-if="configuration.externalLinks.length > 0">
              <span class="ml-3 mt-3 mb-0 menu-label">Links</span>
              <b-dd-divider></b-dd-divider>
              <template v-for="x in Object.entries(configuration.externalLinks)">
                <span v-if="x[0] === 'Break'" :key="x" class="ml-3 mt-3 mb-0 menu-label">External Links</span>
                <b-dd-divider v-if="x[0] === 'Break'" :key="x[0]"></b-dd-divider>
                <b-nav-item v-else :key="x[0]" :href="x[1].link" target="_blank">
                  <span :class="`mdi ${x[1].icon} mr-3 primary`"></span>
                  <span>{{ x[0] }}</span>
                </b-nav-item>
              </template>
            </template>
          </b-nav>
        </nav>
        <div v-if="$store.getters.vendor && getCurrentPlatform.platform === 'web'" class="app-store-container py-2">
          <a v-if="$store.getters.vendor.settings.ios_app_url" :href="$store.getters.vendor.settings.ios_app_url" class="pr-2 pointer" target="_blank">
            <b-img :src="appStoreImg" alt="Download on the App Store" />
          </a>

          <a v-if="$store.getters.vendor.settings.android_app_url" :href="$store.getters.vendor.settings.android_app_url" target="_blank" class="pointer">
            <b-img :src="playStoreImg" alt="Get it on Google Play" />
          </a>
        </div>
        <div class="version-container">
          <div>
            © {{ new Date().getFullYear() }} -
            {{ $store.getters.vendor.settings.copyright_text }}
          </div>
          <div class="spacer"></div>
          <div>{{ appVersion }}</div>
        </div>
      </div>
    </b-sidebar>

    <b-alert v-if="$route.name !== 'home'" :show="alert.show" :variant="alert.variant" class="alert-toast mb-5" dismissible fade @dismissed="hideAlert">
      {{ alert.text }}
    </b-alert>

    <template v-if="vendor && vendor.settings.redirect_mobile_users_to_web && isNative">
      <b-container>
        <b-row align-h="center" class="text-center">
          <b-img class="py-5 vendor-construction-logo" v-if="vendor.logo_image" :alt="vendor.name" :src="vendor.logo_image" :title="vendor.name" lazy @error="vendor.logo_image = null" width="200"/>
          <p>The <b>{{ vendor.name }}</b> mobile application is currently under construction. 
            <br>Please order with the mobile website. Thanks!</p>
          <p><u>Tap the button</u> below to proceed to the mobile website:</p>
        </b-row>
        <b-row align-h="center" class="mt-2">
          <b-button class="btn-cta" :href="vendor.domain">Website <span class="mdi mdi-link-variant primary"></span></b-button>
        </b-row>
      </b-container>
    </template>
    <template v-else-if="vendor && !vendor.is_active_payment">
      <b-row align-h="center" class="text-center">
        <b-img class="py-5 vendor-construction-logo" v-if="vendor.logo_image" :alt="vendor.name" :src="vendor.logo_image" :title="vendor.name" lazy @error="vendor.logo_image = null" width="200"/>
      </b-row>
      <b-row align-h="center" class="text-center">
        <p>This {{ isNative ? "application":"website" }} will become available as soon as <b>{{ vendor.name }}</b> connects their payment processor to the <a href="https://orderprepped.com/">Prepped Platform</a>. 
          <br>Please check back soon. Thanks!</p>
      </b-row>
    </template>
    <template v-else-if="doneDetecting">
      <transition duration="400" mode="out-in" name="fade">
        <template v-if="region">
          <router-view v-cloak class="router-view"></router-view>
        </template>
        <template v-else>
          <Regions :region-options="regionOptions"></Regions>
        </template>
      </transition>
    </template>
    <template v-else>
      <b-row align-h="center">
        <b-col class="mx-auto">
          <b-img :src="loadingGif" class="w-100" fluid></b-img>
        </b-col>
      </b-row>
    </template>
    <minimum-age-modal />
  </b-container>
    <b-modal  size="sm" :visible="Boolean(getCurrentPlatform.marketUrl.length)" hide-footer centered hide-header @hide="onModalClose">
      <div class="d-flex flex-column text-center align-items-center gap">
        <h3>Check out our app!</h3>
        <b-img v-if="$store.getters.vendor" :src="$store.getters.vendor.settings.mobile_logo_url" class="w-25 mb-2" alt="App Logo"/>
        <p>This Website has an mobile application version for your device</p>
        <a :href="getCurrentPlatform.marketUrl" class="pointer" target="_blank">
            <b-img :src="playStoreImg" alt="Get it on Google Play" v-if="getCurrentPlatform.operatingSystem==='android'"/>
            <b-img :src="appStoreImg" alt="Download on the App Store" v-if="getCurrentPlatform.operatingSystem==='ios'"/>
          </a>
      </div>
    </b-modal>
  <sticky-footer />
</div>
