
<b-list-group-item>
  <b-row cols="12">
    <b-col sm="3">
      Order <b>{{ order.code }}</b
      >: <i>{{ order.status }}</i>
    </b-col>
    <b-col sm="3">
      <span class="font-weight-light" style="font-size: 0.8em">TOTAL</span>
      ${{ order.breakdown.total }}
    </b-col>
    <b-col sm="4">
      <router-link :to="`/vendor/${order.vendor.uuid}`">{{
        order.vendor.name
      }}</router-link>
      <span class="float-right">          
        <a v-b-toggle="`products-${index}`" href="#" @click.prevent="toggleDetails(index)">
          [{{ showDetails[index] ? 'Hide Details' : 'Show Details' }}]
          <b-icon-caret-up-fill v-if="showDetails[index]" size="md" />
          <b-icon-caret-down-fill v-else size="md" />
          
        </a>          
      </span>

    </b-col>
    <b-col class="reorder-col" sm="2">
      <b-button class="btn float-right" @click="$emit('reorder', order)">
        <span v-if="!loading">Reorder</span>
        <b-spinner v-else small variant="light" />
      </b-button>
    </b-col>
  </b-row>
  <b-row>
    <b-col sm="12">
      <b-collapse :id="`products-${index}`">
        <b-list-group class="my-2">
          <order-product
            v-for="(product, i) in order.products"
            :key="i"
            :product-wrapper="product"
            :reviewable="true"
          />
          <order-summary :order="order" />
        </b-list-group>
      </b-collapse>
    </b-col>
  </b-row>
  <b-row>
    <b-col sm="12" style="font-size: 0.8em">
      <span class="font-weight-bold font-italic"
        >{{ order.type }}: &nbsp;</span
      >
      <span class="font-italic">{{
        order.type === 'Shipping' ? shippingOrderDate : orderDate
      }}</span>
      <span v-if="order.type == 'Delivery'" class="font-italic"
        >&nbsp;departure time.</span
      >
    </b-col>
  </b-row>
</b-list-group-item>
