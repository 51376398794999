<template>
    <div class="content-card content-card-shadow" v-if="macros.length || macros.calories">

        <div class="content-wrapper" v-if="macros.length || macros.calories" :class="{
            tabContentWrapper: !changeTab,
        }">
            <div class="wrapper">
                <h3 class="header ml-4 mb-4">Edit macros</h3>
                <div class="form macrosForm">
                    <b-form-group label="Calories">
                        <b-form-input v-model="macros.calories" :rules="[rules.required]" label="Calories"
                            class="mr-2" />
                    </b-form-group>
                    <b-form-group label="Protein">
                        <b-form-input v-model="macros.protein" :rules="[rules.required]" label="Protein"
                            class="mr-2" />
                    </b-form-group>
                    <b-form-group label="Carbs">
                        <b-form-input v-model="macros.carbs" :rules="[rules.required]" label="Carbs"
                            class="mr-2" />
                    </b-form-group>
                    <b-form-group label="Fat">
                        <b-form-input v-model="macros.fat" :rules="[rules.required]" label="Fat"
                            class="mr-2" />
                    </b-form-group>
                </div>

            </div>
            <b-row align-h="center">
                <my-button :on-click="saveUserMacros" center :disbled="loading">
                    Save Macros
                </my-button>
            </b-row>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SteakIcon from '@/common/Icons/SteakIcon.vue';
import FatIcon from '@/common/Icons/FatIcon.vue';
import LockIcon from '@/common/Icons/LockIcon.vue';
import CarbsIcon from '@/common/Icons/CarbsIcon.vue';
import InfoCard from '@/common/InfoCard/InfoCard.vue';
import Skeleton from '@/common/Skeleton/Skeleton.vue';
import IconBase from '@/common/Icons/IconBase.vue';
import Button from '@/common/Button/Button.vue';

import { axios } from '^/axios';
import { auth } from '^/auth';
import { rules } from '^/rules';

export default {
    props: {
        changeTab: { type: Function, required: true },
    },
    components: {
        InfoCard,
        Skeleton,
        LockIcon,
        IconBase,
        MyButton: Button,
    },
    computed: {
        ...mapGetters(['user', 'vendor']),
        numberValue: {
            get(value) {
                return Math.floor(value);
            },
        }
    },
    data() {
        return {
            nutritionRecommendation: null,
            SteakIcon,
            FatIcon,
            CarbsIcon,
            loading: true,
            disabled: true,
            isSavingUserMarcors: false,
            isDeletingUserMacros: false,
            macros: {
                calories: 0,
                protein: 0,
                carbs: 0,
                fat: 0,
            },
            rules,
        };
    },
    created: async function () {
        await this.getNutritionRecommendation();
    },

    methods: {
        buttonClick() {
            this.changeTab(3);
        },
        async saveUserMacros() {
            this.isSavingUserMarcors = true;
            
            try {
                this.loading = true;
                let url = `vendor/${this.vendor.uuid}/user/${this.user.uuid}/macros`;

                if (this.macros.updated_by_vendor) {
                    this.macros = await axios.put(url, this.macros);
                } else {
                    this.macros = await axios.post(url, this.macros);
                }
                await this.$store.dispatch('alertError', 'User macros updated');
            } catch (error) {
                await this.$store.dispatch('alertError', error.message);
            }

            this.$emit('getNutritionRecommendation');
            this.isSavingUserMarcors = false;
            this.loading = false;
        },

        async handleDeleteMacros() {
            // try {
            //     this.isDeletingUserMacros = true

            //     await axios.delete(
            //         `/user/${this.activeUser.uuid}/macros`
            //     );
            //     this.macros = {
            //         user_id: this.activeUser.uuid,
            //         calories: null,
            //         protein: null,
            //         carbs: null,
            //         fat: null,
            //         updated_by_vendor: false
            //     }

            //     await this.$store.dispatch('messengerBroadcast', [
            //         'success',
            //         'User macros restored',
            //     ]);
            // } catch (error) {
            //     await this.$store.dispatch('messengerBroadcast', [
            //         'error',
            //         error.message,
            //     ]);
            // } finally {
            //     this.$refs.userMacrosForm.resetValidation();
            //     this.isDeletingUserMacros = false
            // }
        },
        async getNutritionRecommendation() {
            this.loading = true;
            console.log(this.user);
            try {
                const response = await axios.get(
                    `/user/${this.user.uuid}/nutrition-recommendations`
                );

                // this.nutritionRecommendation = response;
                this.macros = response;
                if (!response.calories) {
                    this.changeTab(1);
                    this.disabled = true;
                }
            } catch (e) {
                await this.$store.dispatch('alertError', e.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.macrosForm {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
@media (max-width: 768px) {
    .macrosForm {
        flex-direction: column; 
        justify-content: center;
        align-items: stretch;
        gap: 10px; 
    }

    .form .b-form-group {
        width: 100%; 
    }
}

.header {
  font-size: 20px;
  font-weight: bold;
  font-family: $main-font;
}
</style>