
<b-container>
  <div v-if="loading"></div>
  <div v-else class="wrapper">
    <tabs-wrapper
      v-if="isSmallScreen"
      ref="tabsWrapper"
      :active-color="getPersonalization().theme_color_1"
      :tabs="tabs"
    >
      <div slot="tab1">
        <macros :change-tab="changeTab" />
      </div>
      <div slot="tab2">
        <basic v-once :on-save-finished="onBasicsInfoUpdated" />
      </div>
      <div slot="tab3">
        <progress-component :on-save-finished="onHistoryUpdated" />
      </div>
      <div slot="tab4" v-if="vendor.settings.enable_edit_macros" >
        <EditMacros ref="editMacros" :change-tab="changeTab"/>
      </div>
    </tabs-wrapper>
    <div v-else class="macros">
      <div id="macronutrients" ref="mc" class="content-card-shadow">
        <macros ref="macronutrients" :change-tab="changeTab" />
      </div>
      <div id="basic" class="content-card content-card-shadow">
        <basic
          ref="basics"
          :on-save-finished="updateMacros"
          :change-tab="changeTab"
        />
      </div>
      <div id="progress" class="content-card content-card-shadow">
        <progress-component
          :on-save-finished="updateMacros"
          :change-tab="changeTab"
        />
      </div>
      <div id="editMacros" v-if="vendor.settings.enable_edit_macros">
        <EditMacros ref="editMacros" :change-tab="changeTab" @getNutritionRecommendation="getNutritionRecommendation"/>
      </div>
    </div>
  </div>
</b-container>
